.table {
	.hover-primary {
		&:hover {
			background-color: $secondary;
			color: $white;
		}
	}
}

.countryFilterToolbar {
	width: 150px;
}
