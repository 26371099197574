.filter {
	&__menu {
		z-index: 999 !important;
	}

	&__placeholder {
		white-space: nowrap;
	}
}

.is-invalid {
	.filter__control {
		border-color: $error;

		&:hover {
			border-color: $error;
		}
	}
}
