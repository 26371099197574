.title-wrapper {
	bottom: 0;
	left: 0;
	font-size: 24px;
	color: $white;
}
.page-wrapper {
	margin-top: -16px;
	margin-left: -15px;
	margin-right: -15px;
}
.image-size {
	width: 100%;
	height: 300px;
	object-fit: cover;
}
