/* make the customizations */

// Font
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');
$font-family-base: Nunito, Arial, sans-serif;

// Color
$red: #FB4E4E;
$green: #1AB759;
$blue: #0077C8;
$teal: #EAF9F0;

$primary: $blue;
$secondary: #06038D;
$info: #99C9E9;
$info-light: #DEE7FF;
$danger: $red;
$light: #EBEBEB;
$dark: #5C5C5C;
$light-blue: #f0f6fc;
$light-grey: #cacfcc;
$orange: #E35205;
$error: #DC3545;
$light-grey1: #ced4da;

$theme-colors: (
	"primary": $primary,
	"secondary": $secondary,
	"success": $green,
	"info": $info,
	"danger": $danger,
	"light": $light,
	"dark": $dark,
	"info-light": $info-light
);

:export {
	primary: $primary;
	secondary: $secondary;
	success: $green;
	info: $info;
	danger: $danger;
	orangeLight: #EDC8A3;
	orange: $orange;
	infoLight: $info-light;
};


// Toasts
$toast-background-color: $teal;
$toast-header-background-color: $teal;
$toast-border-color: $green;
$toast-header-color: $green;
$toast-color: $green;

$toast-border-radius: .5rem;

$toast-min-width: 200px;

// List group
$list-group-active-bg: $dark;
$list-group-active-border-color: $dark;

// Z-index
$zindex-toast: 1080;

// Font size
$h1-font-size: 1.5rem;
$headings-line-height: 1.5rem;

/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';
