.loading-shading {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0);
	z-index: 10000;
}

.loading-icon {
	position: absolute;
	top: 45%;
	left: 50%;
}
