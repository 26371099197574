.exercise-card {
	overflow: hidden;
	height: 325px;
	&:hover {
		cursor: pointer;
	}

	&:focus {
		outline: 0 none;
	}

	&.focus-visible,
	&:focus-visible {
		outline: 5px auto $dark !important;
	}

	.img-fluid {
		max-width: 100%;
		height: 177px;
	}

	.card-img {
		height: 177px;
	}

	.card-title {
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
	}

	.action {
		z-index: 1;
	}
}

.guidance-card {
	background-color: $light-blue;

	.card-body {
		p {
			&:last-child {
				margin: 0;
			}
		}
	}
}

.upload-btn {
	opacity: 0;
	right: 0;
	top: 0;
}

.remove-btn-wrapper {
	right: -10px;
	top: -10px;
	bottom: 0;
}

.delete-btn {
	bottom: 27px;
	right: 20px;
	z-index: 1;
}

.edit-btn {
	top: 0;
	right: 20px;
	z-index: 1;
}

.question-card {
	.card-header {
		background-color: $light-blue;
	}
	.remove-btn {
		background-color: $white;
		width: 25px;
		height: 25px;
		padding: 0;
		border-radius: 15px;
		&:hover {
			background-color: $danger;
		}
	}

	.remove-btn[disabled] {
		background: none;

		&:hover {
			background: none;
		}
	}

	.drag-button:hover {
		cursor:grabbing;
	}

	.view-question-card-header {
		height: 45px;
	}
}

.view-exercise-carousel {
	.carousel-control-next, .carousel-control-prev {
		height: 30px;
		top: 40%;
	}
	.carousel-indicators {
		height: 15px;
	}
	.audio-wrapper {
		height: 120px;
	}
}

.question-wrapper:last-child {
	margin-bottom: 120px;
}

.sticky-btn {
	position: fixed;
	bottom: 0;
	background-color: $white;
	width: 100%;
	border-top: 1px solid $light-grey;
	margin-left: -13px;
}

.questionnaire-save-cancel-wrapper {
	margin-right: 8%;
	display: flex;
}

.questionnaire-header {
	background-color: $secondary;
	color: $white;
}

.activity-weekend-header-column-background {
	background-color: $orange;
}

.activity-card-wrapper {
	width: 100%;
	min-height: 250px;
}
