// Search box
.search-box-with-icon {
	position: relative;
	width: 100%;

	.search-icon {
		position: absolute;
		top: 10px;
		left: 10px;
	}

	.clear-btn {
		position: absolute;
		right: 0;
	}

	.form-control {
		padding-left: 35px;
		padding-right: 50px;
	}
}

.error-feedback {
  display: block;
  color: $red;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
}

// additional-field
.additional-field {
	.remove-btn-container {
		position: absolute;
		top: -12px;
		right: -12px;

		.btn-remove {
			background-color: $white;
			width: 28px;
			height: 28px;
			padding: 0;
			border-radius: 15px;

			&:hover {
				background-color: $danger;
			}
		}

	}
}

// partner logo upload button wrapper
.up-load-button-wrapper {
	width: 240px;
}

//show and hide password
.show-hide-password-btn {
	button {
		border-color: $gray-400;
	}
}

.material-category-wrapper {
	margin-bottom: 6%;
}

.modal-backdrop.show, .modal.show {
	z-index: 1051 !important;
}
