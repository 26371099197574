.map-chart {
	position: relative;
	svg {
		outline: none;
	}
	&-controls {
		background-color: $white;
		border: 1px solid $gray-400;
		position: absolute;
		bottom: 0;
		right: 0;
		@include border-radius(4px);

		button {
			padding: 2px 4px;

			&:first-child {
				border-bottom: 1px solid $gray-400;
			}
		}
	}
}
