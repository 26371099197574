.dx-g-bs4-table-cell.position-sticky {
	overflow: visible;
}

.table thead {
	th {
		background-color: theme-color('light') !important;
	}
	tr.filter th {
		background-color: theme-color('dark') !important;
	}
}

.questionnaire-title {
	white-space: pre-wrap;

	.description {
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
	}
}
